<template>
   <h3 class="text-balance font-bold text-link md:text-lg">
      <slot />
      <Icon
         mode="css"
         class="ml-1 inline shrink-0 align-middle"
         name="heroicons-solid:chevron-right"
      />
   </h3>
</template>
